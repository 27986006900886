import { FaGithub } from 'react-icons/fa';

const content = {
  title: 'Coming Soon...',
  subtitle: 'info@karaa.io',
  // links: [
  //   {
  //     to: 'https://github.com/chronisp',
  //     text: 'chronisp',
  //     Icon: FaGithub,
  //     newTab: true,
  //   },
  //   {
  //     to: 'https://github.com/chronisp/gatsby-starter',
  //     text: 'Gatsby Starter',
  //     Icon: FaGithub,
  //     newTab: true,
  //   },
  // ],
};

export default content;
